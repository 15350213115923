import { render, staticRenderFns } from "./safeWorkPermitStateDetail.vue?vue&type=template&id=2bab9837"
import script from "./safeWorkPermitStateDetail.vue?vue&type=script&lang=js"
export * from "./safeWorkPermitStateDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\guyoung\\GuyoungPartner\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2bab9837')) {
      api.createRecord('2bab9837', component.options)
    } else {
      api.reload('2bab9837', component.options)
    }
    module.hot.accept("./safeWorkPermitStateDetail.vue?vue&type=template&id=2bab9837", function () {
      api.rerender('2bab9837', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/swp/safeWorkPermitStateDetail.vue"
export default component.exports